import Navbar_PT from "../../components/Navbar/Navbar_PT";
import "./SobreNos.css";
import banner from "../../assets/images/visitplanbanner.png";
import imgtopico from "../../assets/images/imgtopico.png";
import imgpatrocinios1 from "../../assets/images/imgpatrocinios1.png";
import imgpatrocinios2 from "../../assets/images/imgpatrocinios2.png";
import imgpatrocinios3 from "../../assets/images/imgpatrocinios3.png";
import { useEffect, useState } from "react";

export default function SobreNos_PT() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 1200);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 1200);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      <Navbar_PT menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <div className={`sobre-nos-container ${menuOpen ? "open" : ""}`}>
        <div className="sobre-nos-content">
          <div className="sobre-nos-content-container">
            <img src={banner} className="sobre-nos-image" />
            <div
              className={`sobre-nos-content-image ${menuOpen ? "open" : ""}`}
            >
              <div className="sobrenos-text-container">
                <div className="sobrenos-subtitle">
                  Somos{" "}
                  <span className="sobrenos-titulo">
                    <br></br>VisitPlann
                  </span>
                </div>
                <div className="texto-principal">
                  A equipa da VisitPlann é composta por profissionais com
                  diversas formações e experiência como visão computacional,
                  data science, programação de software, desenvolvimento web, e
                  desenvolvimento de negócios. Diariamente, esta equipe procura
                  soluções inovadoras no setor do turismo.
                </div>
              </div>
              <div className="subtitulo-linha">
                <div className="subtitulo">Os nossos principios</div>
                <div className="grid-container">
                  <div className="sobre-nos-grid-content">
                    <div className="sobre-nos-grid-item-column">
                      <div className="grid-item-row">
                        <img
                          src={imgtopico}
                          className="home-row-img-topico3"
                        ></img>
                        <h4 className="sobrenos-row-title">Comunicação</h4>
                      </div>
                      <div className="sobrenos-text1">
                        Vocacionados para a concepção e desenvolvimento de
                        estratégias de {isLargeScreen && <br />} comunicação.
                      </div>
                    </div>
                    <div className="sobre-nos-grid-item-column">
                      <div className="grid-item-row">
                        <img
                          src={imgtopico}
                          className="home-row-img-topico3"
                        ></img>
                        <h4 className="sobrenos-row-title">Confiança</h4>
                      </div>
                      <div className="sobrenos-text1">
                        Assumimos o compromisso de qualidade e eficiência nos
                        serviços prestados e apresentamos soluções à medida das
                        necessidades de cada cliente.
                      </div>
                    </div>
                    <div className="sobre-nos-grid-item-column">
                      <div className="grid-item-row">
                        <img
                          src={imgtopico}
                          className="home-row-img-topico3"
                        ></img>
                        <h4 className="sobrenos-row-title">Empatia</h4>{" "}
                      </div>
                      <div className="sobrenos-text1">
                        Existimos para os nossos cientes, compreendemos os
                        desafios com os quais {isLargeScreen && <br />}
                        lidam e procuramos ajudá-los a alcançar os{" "}
                        {isLargeScreen && <br />} seus objetivos e maximizar o
                        seu potencial
                      </div>
                    </div>
                    <div className="sobre-nos-grid-item-column">
                      <div className="grid-item-row">
                        <img
                          src={imgtopico}
                          className="home-row-img-topico3"
                        ></img>
                        <h4 className="sobrenos-row-title">Integridade</h4>{" "}
                      </div>
                      <div className="sobrenos-text1">
                        A integridade é um valor fundamental para a nossa equipa
                        e está presente em toda a {isLargeScreen && <br />}{" "}
                        nossa estratégia de atuação
                      </div>
                    </div>
                    <div className="sobre-nos-grid-item-column">
                      <div className="grid-item-row">
                        <img
                          src={imgtopico}
                          className="home-row-img-topico3"
                        ></img>
                        <h4 className="sobrenos-row-title">Excelência</h4>{" "}
                      </div>
                      <div className="sobrenos-text1">
                        Somos proativos e acreditamos fazer{" "}
                        {isLargeScreen && <br />} acontecer. A nossa meta é o
                        sucesso dos nossos clientes que atingimos através de
                        soluções criativas e de excelência.
                      </div>
                    </div>
                    <div className="sobre-nos-grid-item-column">
                      <div className="grid-item-row">
                        <img
                          src={imgtopico}
                          className="home-row-img-topico3"
                        ></img>
                        <h4 className="sobrenos-row-title">Desenvolvimento</h4>{" "}
                      </div>
                      <div className="sobrenos-text1">
                        Equipa multidisciplinar que nos permite toda a
                        implementação (incluindo todas as especificidades e
                        inovações necessárias) através de um processo de
                        colaboração criativa, onde todos os indivíduos trabalham
                        para um objetivo comum.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-patrocionios">
                {/* <div className="home-row-tec-container"></div> */}
                <div className="subtitulo2-container">
                  <div className="subtitulo2">Parceiros</div>
                </div>
                <div className="imagem-patrocinio-container2">
                  <div className="imagem-patrocinio">
                    <div className="patrocinios-container">
                      {" "}
                      <img
                        src={imgpatrocinios1}
                        className="imagens-patrocinios"
                      />
                    </div>
                    <div className="patrocinios-container">
                      {" "}
                      <img
                        src={imgpatrocinios2}
                        className="imagens-patrocinios"
                      />
                    </div>
                    <div className="patrocinios-container">
                      {" "}
                      <img
                        src={imgpatrocinios3}
                        className="imagens-patrocinios"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
