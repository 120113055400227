import Navbar_EN from "../../components/Navbar/Navbar_EN";
import "./Contactos.css";
import loc from "../../assets/images/localizacao.png";
import phone from "../../assets/images/phone.png";
import { useEffect, useState } from "react";
import emailjs from "emailjs-com";
import Lottie from "react-lottie";
import successAnimation from "../../assets/lotties/97240-success.json";

export default function Contactos_EN() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [nome, setNome] = useState("");
  const [sobreNome, setSobreNome] = useState("");
  const [email, setEmail] = useState("");
  const [telemovel, setTelemovel] = useState("");
  const [empresa, setEmpresa] = useState("");
  const [mensagem, setMensagem] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 1200);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 1200);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSending(true);
    const templateParams = {
      from_name: "donotreply@visitplann.com",
      to_name: "info@visitplann.com",
      subject: "[VisitPlann] Contacts",
      head: `
         First name: ${nome}
         Last name: ${sobreNome}
         Email: ${email}
       `,
      message: `
         ${mensagem}
       `,
    };
    emailjs
      .send(
        "service_2rhbkwq",
        "template_9aspign",
        templateParams,
        "kS2D7pdG9RBbyz9xU"
      )
      .then(() => {
        console.log("Email sent successfully");
        setTimeout(() => {
          setNome("");
          setSobreNome("");
          setEmail("");
          setMensagem("");
          setIsSending(false);
        }, 1700);
      })
      .catch((error) => {
        console.error("Error sending email", error);
      });
  };

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: successAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div>
      <Navbar_EN menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <div className={`contactos-containerr ${menuOpen ? "open" : ""}`}>
        <div className="contactoss-content">
          <div className="container-contactos">
            <div className="container-row">
              <div class="contactos-titulo">
                <span class="servicos-gradient-text">Talk to</span>{" "}
                <span class="servicos-subtitulo">Us</span>
              </div>
              {isSending ? (
                <div class="lottie-container">
                  <Lottie options={defaultOptions} height={300} width={300} />
                </div>
              ) : (
                <div className="input-container">
                  <form onSubmit={handleSubmit}>
                    {/* <form> */}
                    <input
                      type="text"
                      placeholder="First Name"
                      value={nome}
                      className="nome"
                      onChange={(e) => setNome(e.target.value)}
                      required
                    />
                    <input
                      type="text"
                      placeholder="Last Name"
                      value={sobreNome}
                      className="nome"
                      onChange={(e) => setSobreNome(e.target.value)}
                      required
                    />
                    <input
                      type="email"
                      placeholder="Email"
                      value={email}
                      className="nome"
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    <textarea
                      className="nome"
                      type="text"
                      name="nome"
                      placeholder="Message"
                      value={mensagem}
                      onChange={(e) => setMensagem(e.target.value)}
                      required
                    />
                    <button className="button" type="submit">
                      Submit
                    </button>
                  </form>
                </div>
              )}
            </div>
            <div
              className="contactos-text-container"
              style={{ height: isLargeScreen ? "275px" : "" }}
            >
              <div className="container-center">
                <div className="texto-titulo-phone">
                  Would you prefer another form of contact?
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                  }}
                >
                  {" "}
                  <div
                    className="texto-contactos1"
                    style={{ width: isLargeScreen ? "70%" : "" }}
                  >
                    <img src={loc} className="home-row-img-loc"></img>
                    Avenida Dom Nuno Álvares Pereira, nº 403,
                    <br />
                    1º Direito Frente, fração AC
                    <br />
                    4750-324 Barcelos
                  </div>
                </div>

                <div
                  className="texto-contactos2"
                  style={{ width: isLargeScreen ? "34%" : "340px" }}
                >
                  <img src={phone} className="home-row-img-loc"></img>
                  +351 938 308 292 <br />
                  +351 965 154 290
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
