import React, { useState, useEffect, useMemo } from "react";
import Navbar_EN from "../../components/Navbar/Navbar_EN";
import "./Home.css";
import googleplay from "../../assets/images/googleplay.png";
import appstore from "../../assets/images/appstore.png";
import imgprincipal from "../../assets/images/imagemprincipal.png";
import imgprincipalmobile from "../../assets/images/imagemprincipal_mobile.png";
import imgobjeto from "../../assets/images/imgobjeto.png";

import img1 from "../../assets/images/img1.png";
import img2 from "../../assets/images/img2.png";
import img3 from "../../assets/images/img3.png";
import img4 from "../../assets/images/img4.png";
import puzzle1 from "../../assets/images/puzzle1.png";
import puzzle2 from "../../assets/images/puzzle2.png";
import puzzle3 from "../../assets/images/puzzle3.png";
import jogos from "../../assets/images/jogos.png";
import videos from "../../assets/images/videos.png";
import audio_guia from "../../assets/images/audio_guia.png";

import { Link } from "react-router-dom";

import Lottie from "react-lottie";
import animation from "../../assets/lotties/BG_Videos.json";
import mapaAnimation from "../../assets/lotties/Mapa_Interno.json";
import soundwaveAnimation from "../../assets/lotties/Soundwave.json";
import pbAnimation from "../../assets/lotties/teste_pb.json";

const images = [img1, img2, img3, img4];
const intervalTime = 5000;

export default function Home_EN() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [progressWidth, setProgressWidth] = useState(0);
  const [startTime, setStartTime] = useState(Date.now());
  const [activeCard, setActiveCard] = useState("audio");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1200);
  const [stoppedAudio, setStoppedAudio] = useState(false);
  const [stoppedVideo, setStoppedVideo] = useState(
    isSmallScreen ? false : true
  );

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1200);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: isSmallScreen ? true : false,
    animationData: mapaAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const defaultOptionsSoundwave = useMemo(
    () => ({
      loop: true,
      autoplay: isSmallScreen ? true : false,
      animationData: soundwaveAnimation,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    }),
    [activeCard]
  );

  const defaultOptionsPb = useMemo(
    () => ({
      loop: true,
      autoplay: isSmallScreen ? true : false,
      animationData: pbAnimation,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    }),
    [activeCard]
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1200);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const elapsedTime = Date.now() - startTime;
      const timePercentage = (elapsedTime / intervalTime) * 100;
      setProgressWidth(timePercentage);

      if (elapsedTime >= intervalTime) {
        clearInterval(interval);
        const nextIndex = (currentIndex + 1) % images.length;
        setCurrentIndex(nextIndex);
        setStartTime(Date.now());
        setProgressWidth(0);
      }
    }, 100);

    return () => clearInterval(interval);
  }, [currentIndex, startTime]);

  const selectImage = (index) => {
    setCurrentIndex(index);
    setStartTime(Date.now());
    setProgressWidth(0);
  };

  const handleCardClick = (card) => {
    setActiveCard(card);
  };

  console.log(activeCard);

  return (
    <div>
      <Navbar_EN menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <div className={`home-container ${menuOpen ? "open" : ""}`}>
        <div className="home-content">
          <div className="home-title-container">
            <div className="home-title">Explore beyond your eyes</div>
          </div>

          <div className="home-subtitle-container">
            <div className="home-subtitle">
              With our app, discover stories, traditions and curiosities in a
              simplified and enriching way, using artificial intelligence.
            </div>
          </div>
          <div className="home-row-container">
            <div className="home-row">
              <Link
                to="https://apps.apple.com/br/app/visitplann/id6472165155"
                className="home-row-link"
              >
                <img
                  src={appstore}
                  className="home-row-img apple"
                  alt="App Store"
                />
              </Link>
              <Link
                to="https://play.google.com/store/apps/details?id=visitplann.com"
                className="home-row-link"
              >
                <img
                  src={googleplay}
                  className="home-row-img google"
                  alt="Google Play Store"
                />
              </Link>
            </div>
          </div>
          <div className="img-phone-home">
            <img
              src={isSmallScreen ? imgprincipalmobile : imgprincipal}
              className="home-row-content-img"
              alt="Main"
            />
          </div>

          {!isMobile ? (
            <>
              <div className="imagem-patrocinio-container">
                <p className="home-text1new">
                  A new{" "}
                  <span className="home-text1new-grey">
                    way of getting to know cities
                  </span>
                </p>
              </div>

              <div className="container-new">
                <div className="topics-column">
                  {["External Map", "GPS", "Routes", "List"].map(
                    (title, index) => (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "start",
                        }}
                      >
                        <div
                          key={index}
                          className={`section ${
                            currentIndex === index ? "active" : ""
                          }`}
                          onClick={() => selectImage(index)}
                        >
                          <div className="dot-column">
                            {currentIndex === index ? (
                              <span className="yellow-dot"></span>
                            ) : (
                              <span className="white-dot"></span>
                            )}
                          </div>
                          <div className="dot-column2">
                            <div className="titulo-menu-escolhas">{title}</div>
                            {/* <div
                            className="progress-bar"
                            style={{
                              display:
                                currentIndex === index ? "block" : "none",
                            }}
                          >
                            <div
                              className="progress-bar-fill"
                              style={{ width: `${progressWidth}%` }}
                            ></div>
                          </div> */}
                            <div
                              className={`sub-titulo-progress-bar ${
                                currentIndex === index ? "active" : ""
                              }`}
                            >
                              {title === "External Map"
                                ? "Explore all the points of interest around you with the functionality of our external map."
                                : title === "GPS"
                                ? "We plot your route to your point of interest."
                                : title === "Routes"
                                ? "Group several locations together to make it easier to choose which points to visit."
                                : title === "List"
                                ? "Creating itineraries around the city based on lists and preferences."
                                : ""}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>

                {/* <div className="images-column"> */}
                {/* <div className="image-container"> */}
                <img
                  className="display-image"
                  src={images[currentIndex]}
                  alt={`Display ${currentIndex}`}
                  style={{
                    display: currentIndex !== null ? "block" : "none",
                  }}
                />
                {/* </div> */}
                {/* </div> */}
              </div>
            </>
          ) : (
            <>
              <div className="imagem-patrocinio-container">
                <p className="home-text1new">
                  A new{" "}
                  <span className="home-text1new-grey">
                    way of getting to know cities
                  </span>
                </p>
              </div>

              <div className="container-new-column">
                <div className="topics-column">
                  {["External Map", "GPS", "Routes", "List"].map(
                    (title, index) => (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "start",
                        }}
                      >
                        <div
                          key={index}
                          className={`home-column-section ${
                            currentIndex === index ? "active" : ""
                          } ${title === "External Map" ? "first" : "others"}`}
                          onClick={() => selectImage(index)}
                        >
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "start",
                            }}
                          >
                            <div
                              className="home-row"
                              style={{
                                justifyContent: "start",
                                alignitems: "center",
                              }}
                            >
                              <div className="dot-column">
                                {currentIndex === index ? (
                                  <span className="yellow-dot"></span>
                                ) : (
                                  <span className="white-dot"></span>
                                )}
                              </div>
                              <div className="dot-column2">
                                <div className="titulo-menu-escolhas">
                                  {title}
                                </div>
                                {/* <div
                              className="progress-bar"
                              style={{
                                display:
                                  currentIndex === index ? "block" : "none",
                              }}
                            >
                              <div
                                className="progress-bar-fill"
                                style={{ width: `${progressWidth}%` }}
                              ></div>
                            </div> */}
                                <div className="sub-titulo-progress-bar">
                                  {title === "External Map"
                                    ? "Explore all the points of interest around you with the functionality of our external map."
                                    : title === "GPS"
                                    ? "We plot your route to your point of interest."
                                    : title === "Routes"
                                    ? "Group several locations together to make it easier to choose which points to visit."
                                    : title === "List"
                                    ? "Creating itineraries around the city based on lists and preferences."
                                    : ""}
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <div className="images-column"> */}
                          {/* <div className="image-container"> */}
                          <img
                            className="display-image"
                            src={images[currentIndex]}
                            alt={`Display ${currentIndex}`}
                            style={{
                              display:
                                currentIndex === index ? "block" : "none",
                            }}
                          />
                          {/* </div> */}
                          {/* </div> */}
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </>
          )}

          <div className="grid-container">
            <div className="grid-content"></div>
          </div>
          <div className="home-row-tec-container-home">
            <div className="imagem-patrocinio-container">
              <p className="home-text2new">
                A new way{" "}
                <span className="home-text1new-grey">to visit museums</span>
              </p>
            </div>
            <div className="home-row-tec-container-home-content">
              <div className="container-roww">
                <div className="container-roww-content">
                  {/* <img src={imgtest} className="home-row-img" alt="Main" /> */}
                  {/* <div style={{ height: "500px" }}>
                    <Lottie
                      options={defaultOptions}
                      height={isSmallScreen ? "auto" : 495}
                      width={isSmallScreen ? "100%" : 599.5}
                    />
                  </div> */}
                  {isMobile ? (
                    <>
                      <Lottie
                        options={defaultOptions}
                        height={isSmallScreen ? "auto" : 495}
                        width={isSmallScreen ? "100%" : 599.5}
                      />
                    </>
                  ) : (
                    <div>
                      <Lottie
                        options={defaultOptions}
                        height={isSmallScreen ? "auto" : 495}
                        width={isSmallScreen ? "100%" : 599.5}
                      />
                    </div>
                  )}

                  <div className="home-animacao-content">
                    <div className="home-text2-subtitle">Internal Visit</div>
                    <div className="home-text3-subtitle">
                      Intelligent navigation inside the monument via interactive
                      maps.
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-roww" style={{ marginTop: "50px" }}>
                <div className="container-roww-content">
                  {isMobile ? (
                    <>
                      <img
                        src={imgobjeto}
                        style={{
                          height: isSmallScreen ? "auto" : "495px",
                          width: isSmallScreen ? "100%" : "599.5px",
                        }}
                        alt="Main"
                      />
                      <div className="home-animacao-content">
                        <div className="home-text2-subtitle">Detection</div>
                        <div className="home-text3-subtitle">
                          Using a computer vision algorithm, the application is
                          able to identify the real-time image of the tourist
                          spot chosen by the user.
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="home-animacao-content">
                        <div className="home-text2-subtitle">Detection</div>
                        <div className="home-text3-subtitle">
                          Using a computer vision algorithm, the application is
                          able to identify the real-time image of the tourist
                          spot chosen by the user.
                        </div>
                      </div>
                      <img
                        src={imgobjeto}
                        style={{
                          height: isSmallScreen ? "auto" : "495px",
                          width: isSmallScreen ? "100%" : "599.5px",
                        }}
                        alt="Main"
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="app-container">
            <div className="imagem-patrocinio-container">
              <p className="home-text2new" style={{ marginBottom: "10px" }}>
                A new way{" "}
                <span className="home-text1new-grey">to visit museums</span>
              </p>
            </div>
            <div
              style={{
                width: isSmallScreen ? "100%" : "40%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <div className="home-subtitle">
                With our app, discover stories, traditions and curiosities in a
                simplified and enriching way, using artificial intelligence.
              </div>
            </div>
            <div
              className="card-container"
              // onMouseLeave={() => setActiveCard(null)}
            >
              <div
                className={`card ${
                  !isSmallScreen
                    ? activeCard === "audio"
                      ? "large"
                      : "small"
                    : "small"
                }`}
                style={{ marginRight: isSmallScreen ? "" : "10px" }}
                // onMouseEnter={() => handleCardClick("audio")}
                onMouseEnter={() => {
                  handleCardClick("audio");
                  setStoppedAudio(false);
                  setStoppedVideo(true);
                }}
                // onMouseLeave={() => {
                //   setStoppedAudio(true);
                // }}
              >
                <Lottie
                  options={defaultOptionsSoundwave}
                  isStopped={stoppedAudio}
                  className="lottie"
                  style={{
                    width: "100%",
                    height: "50%",
                  }}
                />
                <img src={audio_guia} alt="Overlay" className="overlay-image" />
                <div
                  className={`text-overlay ${
                    !isSmallScreen
                      ? activeCard === "audio"
                        ? "large"
                        : "small"
                      : "small"
                  }`}
                >
                  <div
                    className={`home-text3 ${
                      !isSmallScreen
                        ? activeCard === "audio"
                          ? "large"
                          : "small"
                        : "small"
                    }`}
                  >
                    Audio Guide
                  </div>
                  <div
                    className={`sub-titulo-animacao2 ${
                      !isSmallScreen
                        ? activeCard === "audio"
                          ? "large"
                          : "small"
                        : "small"
                    }`}
                    style={{ height: isSmallScreen ? "290px" : "" }}
                  >
                    An audio guide enhances your experience of a monument by
                    providing historical context and fascinating details, making
                    the visit more engaging and informative.
                  </div>
                </div>
              </div>

              <div
                className={`card ${
                  !isSmallScreen
                    ? activeCard === "video"
                      ? "large"
                      : "small"
                    : "small"
                }`}
                // onMouseEnter={() => handleCardClick("audio")}
                style={{
                  marginRight: isSmallScreen ? "" : "10px",
                  marginLeft: isSmallScreen ? "" : "10px",
                }}
                onMouseEnter={() => {
                  handleCardClick("video");
                  setStoppedVideo(false);
                  setStoppedAudio(true);
                }}
                // onMouseLeave={() => {
                //   setStoppedVideo(true);
                // }}
              >
                <Lottie
                  options={defaultOptionsPb}
                  isStopped={stoppedVideo}
                  style={{
                    opacity: activeCard !== "video" ? 1 : 0.22,
                    borderRadius: "20px",
                  }}
                />
                <img src={videos} alt="Overlay" className="overlay-image" />
                <div
                  className={`text-overlay ${
                    !isSmallScreen
                      ? activeCard === "video"
                        ? "large"
                        : "small"
                      : "small"
                  }`}
                >
                  <div
                    className={`home-text3 ${
                      !isSmallScreen
                        ? activeCard === "video"
                          ? "large"
                          : "small"
                        : "small"
                    }`}
                  >
                    Videos
                  </div>
                  <div
                    className={`sub-titulo-animacao2 ${
                      !isSmallScreen
                        ? activeCard === "video"
                          ? "large"
                          : "small"
                        : "small"
                    }`}
                    style={{ height: isSmallScreen ? "230px" : "" }}
                  >
                    Video at a monument offers a visual and engaging way to
                    learn about its history and significance, making the visit
                    informative and fun.
                  </div>
                </div>
              </div>

              <div
                className={`card ${
                  !isSmallScreen
                    ? activeCard === "games"
                      ? "large"
                      : "small"
                    : "small"
                }`}
                style={{ marginLeft: isSmallScreen ? "" : "10px" }}
                onMouseEnter={() => {
                  handleCardClick("games");
                  setStoppedAudio(true);
                  setStoppedVideo(true);
                }}
              >
                <img
                  src={
                    !isSmallScreen
                      ? activeCard === "games"
                        ? puzzle2
                        : puzzle1
                      : puzzle3
                  }
                  style={{
                    width: !isSmallScreen ? "100%" : "80%",
                    opacity: activeCard !== "games" ? 1 : 0.22,
                    position: isSmallScreen ? "absolute" : "",
                    top: 0,
                    right: 0,
                  }}
                  className="game-image"
                />
                <img src={jogos} alt="Overlay" className="overlay-image" />
                <div
                  className={`text-overlay ${
                    !isSmallScreen
                      ? activeCard === "games"
                        ? "large"
                        : "small"
                      : "small"
                  }`}
                >
                  <div
                    className={`home-text3 ${
                      !isSmallScreen
                        ? activeCard === "games"
                          ? "large"
                          : "small"
                        : "small"
                    }`}
                  >
                    Games
                  </div>
                  <div
                    className={`sub-titulo-animacao2 ${
                      !isSmallScreen
                        ? activeCard === "games"
                          ? "large"
                          : "small"
                        : "small"
                    }`}
                    style={{ height: isSmallScreen ? "300px" : "" }}
                  >
                    Games at a monument turns learning into fun, offering
                    interactive challenges that educate about its history and
                    importance, making the visit enjoyable and engaging for
                    youngsters.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
