import Navbar_PT from "../../components/Navbar/Navbar_PT";
import "./Servicos.css";
import { useEffect, useState } from "react";
import servicos1 from "../../assets/images/servicos1.png";
import servicos2 from "../../assets/images/servicos2.png";
import servicos3 from "../../assets/images/servicos3.png";

export default function Servicos_PT() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 1200);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 1200);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      <Navbar_PT menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <div className={`sobre-nos-container ${menuOpen ? "open" : ""}`}>
        <div className="servicos-content">
          <div class="servicos-titulo">
            <div className="sobrenos-subtitle">
              Características e{" "}
              <span className="sobrenos-titulo">
                <br></br>Funcionalidades
              </span>
            </div>
          </div>
          {/* <div className="complementos-text-container">
            <div className="sobrenos-subtitle">
              Características e{" "}
              <span className="sobrenos-titulo">
                <br></br>Funcionalidades
              </span>
            </div>
          </div> */}
          <div className="funcionalidades-section">
            <div className="imagem-patrocinio-container">
              <div className="funcionalidade-item">
                <img src={servicos1} className="imagens-servicos" />
                <div className="servicos-texto1">
                  <div className="titulo-texto">Visão Computacional</div>
                  <div className="implementacao">
                    <h1>Implementação:</h1>
                    <ul>
                      <li className="textservicos">
                        Deep Learning com TensorFlow para treinar modelos de
                        reconhecimento de objetos.
                      </li>
                      <li className="textservicos">
                        Modelos treinados com imagens de objetos dos monumentos.
                      </li>
                      <li className="textservicos">
                        Processamento no dispositivo com TensorFlowLite.
                      </li>
                    </ul>
                  </div>
                  <div className="tecnologias">
                    <h1>Tecnologias:</h1>
                    <ul>
                      <li className="textservicos">
                        Redes Neurais Convolucionais (CNNs) para reconhecimento
                        de imagens.
                      </li>
                      <li className="textservicos">
                        Transferência de aprendizagem com modelos pré-treinados.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="imagem-patrocinio-container">
              {isLargeScreen ? (
                <div className="funcionalidade-item">
                  <div className="servicos-texto1">
                    <div className="titulo-texto">Modelos de Linguagem</div>
                    <div className="implementacao">
                      <h1>Implementação:</h1>
                      <ul>
                        <li className="textservicos">
                          Técnicas de processamento de linguagem natural (PNL)
                          para analisar e gerar texto com base na bibliografia
                          do monumento.
                        </li>
                        <li className="textservicos">
                          Sistema de gestão de conteúdo para organizar e
                          atualizar os roteiros que guiam os visitantes no
                          interior do monumento.
                        </li>
                      </ul>
                    </div>
                    <div className="tecnologias">
                      <h1>Tecnologias:</h1>
                      <ul>
                        <li className="textservicos">
                          Modelos de linguagem pré-treinados como GPT-3 para
                          geração de texto.
                        </li>
                        <li className="textservicos">
                          Bibliotecas de PNL como spaCy ou NLTK para
                          processamento e análise de texto.
                        </li>
                        <li className="textservicos">
                          Sistema de gestão de conteúdo (CMS) para manutenção,
                          atualização e automatização do processo.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <img src={servicos2} className="imagens-servicos" />
                </div>
              ) : (
                <div className="funcionalidade-item">
                  <img src={servicos2} className="imagens-servicos" />
                  <div className="servicos-texto1">
                    <div className="titulo-texto">Modelos de Linguagem</div>
                    <div className="implementacao">
                      <h1>Implementação:</h1>
                      <ul>
                        <li className="textservicos">
                          Técnicas de processamento de linguagem natural (PNL)
                          para analisar e gerar texto com base na bibliografia
                          do monumento.
                        </li>
                        <li className="textservicos">
                          Sistema de gestão de conteúdo para organizar e
                          atualizar os roteiros que guiam os visitantes no
                          interior do monumento.
                        </li>
                      </ul>
                    </div>
                    <div className="tecnologias">
                      <h1>Tecnologias:</h1>
                      <ul>
                        <li className="textservicos">
                          Modelos de linguagem pré-treinados como GPT-3 para
                          geração de texto.
                        </li>
                        <li className="textservicos">
                          Bibliotecas de PNL como spaCy ou NLTK para
                          processamento e análise de texto.
                        </li>
                        <li className="textservicos">
                          Sistema de gestão de conteúdo (CMS) para manutenção,
                          atualização e automatização do processo.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="imagem-patrocinio-container">
              <div className="funcionalidade-item">
                <img src={servicos3} className="imagens-servicos" />
                <div className="servicos-texto1">
                  <div className="titulo-texto">Tecnologia com Beacons BLE</div>
                  <div className="implementacao">
                    <h1>Implementação:</h1>
                    <ul>
                      <li className="textservicos">
                        Beacons BLE instalados estrategicamente para otimizar a
                        cobertura do sinal.
                      </li>
                      <li className="textservicos">
                        Sistema de backend para gestão de dados e sinais dos
                        beacons BLE.
                      </li>
                    </ul>
                  </div>
                  <div className="tecnologias">
                    <h1>Tecnologias:</h1>
                    <ul>
                      <li className="textservicos">
                        Sinalizadores Bluetooth Low Energy (BLE) para detecção
                        de proximidade.
                      </li>
                      <li className="textservicos">
                        Algoritmos de triangulação e IA para posicionamento
                        indoor preciso.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
