import Navbar_EN from "../../components/Navbar/Navbar_EN";
import "./Servicos.css";
import { useEffect, useState } from "react";
import servicos1 from "../../assets/images/servicos1.png";
import servicos2 from "../../assets/images/servicos2.png";
import servicos3 from "../../assets/images/servicos3.png";

export default function Servicos_EN() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 1200);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 1200);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      <Navbar_EN menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <div className={`sobre-nos-container ${menuOpen ? "open" : ""}`}>
        <div className="servicos-content">
          <div class="servicos-titulo">
            <div className="sobrenos-subtitle">
              Features and{" "}
              <span className="sobrenos-titulo">
                <br></br>Functionalities
              </span>
            </div>
          </div>
          {/* <div className="complementos-text-container">
            <div className="sobrenos-subtitle">
              Características e{" "}
              <span className="sobrenos-titulo">
                <br></br>Funcionalidades
              </span>
            </div>
          </div> */}
          <div className="funcionalidades-section">
            <div className="imagem-patrocinio-container">
              <div className="funcionalidade-item">
                <img src={servicos1} className="imagens-servicos" />
                <div className="servicos-texto1">
                  <div className="titulo-texto">Computer Vision</div>
                  <div className="implementacao">
                    <h1>Implementation:</h1>
                    <ul>
                      <li className="textservicos">
                        Deep Learning with TensorFlow to train object
                        recognition models.
                      </li>
                      <li className="textservicos">
                        Models trained with images of monument objects.
                      </li>
                      <li className="textservicos">
                        On-device processing with TensorFlowLite.
                      </li>
                    </ul>
                  </div>
                  <div className="tecnologias">
                    <h1>Technologies:</h1>
                    <ul>
                      <li className="textservicos">
                        Convolutional Neural Networks (CNNs) for image
                        recognition.
                      </li>
                      <li className="textservicos">
                        Transfer of learning with pre-trained models.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="imagem-patrocinio-container">
              {isLargeScreen ? (
                <div className="funcionalidade-item">
                  <div className="servicos-texto1">
                    <div className="titulo-texto">Language Models</div>
                    <div className="implementacao">
                      <h1>Implementation:</h1>
                      <ul>
                        <li className="textservicos">
                          Natural language processing (NLP) techniques to
                          analyse and generate text based on the monument's
                          bibliography.
                        </li>
                        <li className="textservicos">
                          Content management system to organise and update the
                          itineraries that guide visitors inside the monument.
                        </li>
                      </ul>
                    </div>
                    <div className="tecnologias">
                      <h1>Technologies:</h1>
                      <ul>
                        <li className="textservicos">
                          Pre-trained language models such as GPT-3 for text
                          generation.
                        </li>
                        <li className="textservicos">
                          NLP libraries such as spaCy or NLTK for processing and
                          analysing text.
                        </li>
                        <li className="textservicos">
                          Content management system (CMS) for maintaining,
                          updating and automating the process.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <img src={servicos2} className="imagens-servicos" />
                </div>
              ) : (
                <div className="funcionalidade-item">
                  <img src={servicos2} className="imagens-servicos" />
                  <div className="servicos-texto1">
                    <div className="titulo-texto">Language Models</div>
                    <div className="implementacao">
                      <h1>Implementation:</h1>
                      <ul>
                        <li className="textservicos">
                          Natural language processing (NLP) techniques to
                          analyse and generate text based on the monument's
                          bibliography.
                        </li>
                        <li className="textservicos">
                          Content management system to organise and update the
                          itineraries that guide visitors inside the monument.
                        </li>
                      </ul>
                    </div>
                    <div className="tecnologias">
                      <h1>Technologies:</h1>
                      <ul>
                        <li className="textservicos">
                          Pre-trained language models such as GPT-3 for text
                          generation.
                        </li>
                        <li className="textservicos">
                          NLP libraries such as spaCy or NLTK for processing and
                          analysing text.
                        </li>
                        <li className="textservicos">
                          Content management system (CMS) for maintaining,
                          updating and automating the process.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="imagem-patrocinio-container">
              <div className="funcionalidade-item">
                <img src={servicos3} className="imagens-servicos" />
                <div className="servicos-texto1">
                  <div className="titulo-texto">BLE Beacon Technology</div>
                  <div className="implementacao">
                    <h1>Implementation:</h1>
                    <ul>
                      <li className="textservicos">
                        BLE beacons strategically installed to optimise signal
                        coverage.
                      </li>
                      <li className="textservicos">
                        Backend system for managing data and signals from BLE
                        beacons.
                      </li>
                    </ul>
                  </div>
                  <div className="tecnologias">
                    <h1>Technologies:</h1>
                    <ul>
                      <li className="textservicos">
                        Bluetooth Low Energy (BLE) beacons for proximity
                        detection.
                      </li>
                      <li className="textservicos">
                        Triangulation algorithms and AI for precise indoor
                        positioning.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
