import Navbar_EN from "../../components/Navbar/Navbar_EN";
import "./SobreNos.css";
import banner from "../../assets/images/visitplanbanner.png";
import imgtopico from "../../assets/images/imgtopico.png";
import imgpatrocinios1 from "../../assets/images/imgpatrocinios1.png";
import imgpatrocinios2 from "../../assets/images/imgpatrocinios2.png";
import imgpatrocinios3 from "../../assets/images/imgpatrocinios3.png";
import { useEffect, useState } from "react";

export default function SobreNos_EN() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 1200);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 1200);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      <Navbar_EN menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <div className={`sobre-nos-container ${menuOpen ? "open" : ""}`}>
        <div className="sobre-nos-content">
          <div className="sobre-nos-content-container">
            <img src={banner} className="sobre-nos-image" />
            <div
              className={`sobre-nos-content-image ${menuOpen ? "open" : ""}`}
            >
              <div className="sobrenos-text-container">
                <div className="sobrenos-subtitle">
                  We are{" "}
                  <span className="sobrenos-titulo">
                    <br></br>VisitPlann
                  </span>
                </div>
                <div className="texto-principal">
                  VisitPlann's team is made up of professionals with diverse
                  backgrounds and experience such as computer vision, data
                  science, software programming, web development and business
                  development. Every day, this team looks for innovative
                  solutions in the tourism sector.
                </div>
              </div>
              <div className="subtitulo-linha">
                <div className="subtitulo">Our principles</div>
                <div className="grid-container">
                  <div className="sobre-nos-grid-content">
                    <div className="sobre-nos-grid-item-column">
                      <div className="grid-item-row">
                        <img
                          src={imgtopico}
                          className="home-row-img-topico3"
                        ></img>
                        <h4 className="sobrenos-row-title">Communication</h4>
                      </div>
                      <div className="sobrenos-text1">
                        Specialised in the design and development of
                        communication strategies.
                      </div>
                    </div>
                    <div className="sobre-nos-grid-item-column">
                      <div className="grid-item-row">
                        <img
                          src={imgtopico}
                          className="home-row-img-topico3"
                        ></img>
                        <h4 className="sobrenos-row-title">Confidence</h4>
                      </div>
                      <div className="sobrenos-text1">
                        We are committed to quality and efficiency in the
                        services we provide and we offer solutions tailored to
                        the needs of each client.
                      </div>
                    </div>
                    <div className="sobre-nos-grid-item-column">
                      <div className="grid-item-row">
                        <img
                          src={imgtopico}
                          className="home-row-img-topico3"
                        ></img>
                        <h4 className="sobrenos-row-title">Empathy</h4>{" "}
                      </div>
                      {/* <div className="sobrenos-text1">
                        Existimos para os nossos cientes, compreendemos os
                        desafios com os quais {isLargeScreen && <br />}
                        lidam e procuramos ajudá-los a alcançar os{" "}
                        {isLargeScreen && <br />} seus objetivos e maximizar o
                        seu potencial
                      </div> */}
                      <div className="sobrenos-text1">
                        We exist for our clients, we understand the challenges
                        they face and we strive to help them achieve their goals
                        and maximise their potential.
                      </div>
                    </div>
                    <div className="sobre-nos-grid-item-column">
                      <div className="grid-item-row">
                        <img
                          src={imgtopico}
                          className="home-row-img-topico3"
                        ></img>
                        <h4 className="sobrenos-row-title">Integrity</h4>{" "}
                      </div>
                      <div className="sobrenos-text1">
                        Integrity is a fundamental value for our team and is
                        present in our entire operating strategy.
                      </div>
                    </div>
                    <div className="sobre-nos-grid-item-column">
                      <div className="grid-item-row">
                        <img
                          src={imgtopico}
                          className="home-row-img-topico3"
                        ></img>
                        <h4 className="sobrenos-row-title">Excellence</h4>{" "}
                      </div>
                      <div className="sobrenos-text1">
                        We are proactive and believe in making things happen.
                        Our goal is the success of our clients, which we achieve
                        through creative solutions and excellence.
                      </div>
                    </div>
                    <div className="sobre-nos-grid-item-column">
                      <div className="grid-item-row">
                        <img
                          src={imgtopico}
                          className="home-row-img-topico3"
                        ></img>
                        <h4 className="sobrenos-row-title">Development</h4>{" "}
                      </div>
                      <div className="sobrenos-text1">
                        A multidisciplinary team that allows us to carry out the
                        entire implementation (including all the necessary
                        specificities and innovations) through a process of
                        creative collaboration, where all individuals work
                        towards a common goal.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-patrocionios">
                {/* <div className="home-row-tec-container"></div> */}
                <div className="subtitulo2-container">
                  <div className="subtitulo2">Parceiros</div>
                </div>
                <div className="imagem-patrocinio-container2">
                  <div className="imagem-patrocinio">
                    <div className="patrocinios-container">
                      {" "}
                      <img
                        src={imgpatrocinios1}
                        className="imagens-patrocinios"
                      />
                    </div>
                    <div className="patrocinios-container">
                      {" "}
                      <img
                        src={imgpatrocinios2}
                        className="imagens-patrocinios"
                      />
                    </div>
                    <div className="patrocinios-container">
                      {" "}
                      <img
                        src={imgpatrocinios3}
                        className="imagens-patrocinios"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
