import React, { useEffect } from "react";
import { Route, BrowserRouter, Routes, useNavigate } from "react-router-dom";
import Home_PT from "./pages/home/Home_PT";
import Home_EN from "./pages/home/Home_EN";
import SobreNos_PT from "./pages/sobre_nos/SobreNos_PT";
import SobreNos_EN from "./pages/sobre_nos/SobreNos_EN";
import Complementos_PT from "./pages/complementos/Complementos_PT";
import Complementos_EN from "./pages/complementos/Complementos_EN";
import Contactos_PT from "./pages/contactos/Contactos_PT";
import Contactos_EN from "./pages/contactos/Contactos_EN";
import Servicos_PT from "./pages/servicos/Servicos_PT";
import Servicos_EN from "./pages/servicos/Servicos_EN";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/pt" element={<Home_PT />} />
        <Route exact path="/en" element={<Home_EN />} />
        <Route exact path="/pt/sobre-nos" element={<SobreNos_PT />} />
        <Route exact path="/en/sobre-nos" element={<SobreNos_EN />} />
        <Route exact path="/pt/complementos" element={<Complementos_PT />} />
        <Route exact path="/en/complementos" element={<Complementos_EN />} />
        <Route exact path="/pt/contactos" element={<Contactos_PT />} />
        <Route exact path="/en/contactos" element={<Contactos_EN />} />
        <Route exact path="/pt/servicos" element={<Servicos_PT />} />
        <Route exact path="/en/servicos" element={<Servicos_EN />} />
        <Route path="/" element={<LanguageRedirect />} />
      </Routes>
    </BrowserRouter>
  );
}
export default App;
function LanguageRedirect() {
  const navigate = useNavigate();  useEffect(() => {
    const userLang = navigator.language || navigator.userLanguage;
    if (userLang.includes("pt")) {
      navigate("/pt");
    } else {
      navigate("/en");
    }
  }, [navigate]);  return null;
}