import "./Navbar.css";
import logoPrincipal from "../../assets/images/logoprincipal.png";
import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import en from "../../assets/images/en.png";

export default function Navbar_PT({ menuOpen, setMenuOpen }) {
  const location = useLocation();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    setMenuOpen(false);
  }, [location.pathname]);

  return (
    <div className="navbar">
      <div className="navbar-content">
        <Link to="/pt" className="navbar-option-image-container">
          <img
            src={logoPrincipal}
            className="navbar-img"
            alt="Logo Principal"
          />
        </Link>
        <div className="navbar-option-container">
          <div
            className={`navbar-option-subcontainer ${menuOpen ? "active" : ""}`}
          >
            <div className="navbar-subcontainer-column">
              <div className="navbar-option-subcontainer-content">
                <Link
                  to="/pt/servicos"
                  className={`navbar-option ${
                    location.pathname === "/pt/servicos" ? "active" : ""
                  }`}
                >
                  Serviços
                </Link>
                <Link
                  to="/pt/complementos"
                  className={`navbar-option ${
                    location.pathname === "/pt/complementos" ? "active" : ""
                  }`}
                >
                  Complementos
                </Link>
                <Link
                  to="/pt/sobre-nos"
                  className={`navbar-option ${
                    location.pathname === "/pt/sobre-nos" ? "active" : ""
                  }`}
                >
                  Sobre Nós
                </Link>
                <Link
                  to="/pt/contactos"
                  className={`navbar-option ${
                    location.pathname === "/pt/contactos" ? "active" : ""
                  }`}
                >
                  Contactos
                </Link>
                <Link to="/en" className="navbar-option">
                  <div className="contactos-container-img">
                    <div className="grid-item-row-navbar">
                      <img src={en} className="home-row-img-language"></img>
                      <div className="navbar-country-text">EN</div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div
            className={`visit-menu ${menuOpen ? "open" : ""}`}
            onClick={toggleMenu}
          >
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
}
