import Navbar_EN from "../../components/Navbar/Navbar_EN";
import "./Complementos.css";
import img1complementos from "../../assets/images/imagem1complementos.png";
import img2complementos from "../../assets/images/imagem2complementos.png";
import { useEffect, useState } from "react";

export default function Complementos_EN() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1200);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1200);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      <Navbar_EN menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <div className={`home-container ${menuOpen ? "open" : ""}`}>
        <div className="complementos-content">
          <div className="complementos-text-container">
            <div class="complementos-titulo-page">
              <span class="servicos-gradient-text">Services</span>{" "}
              <span class="servicos-subtitulo">Complementary</span>
            </div>
            <div className="text-introducao">
              Enrich the experience with our additional services, complementing
              your main product. We offer comprehensive marketing and
              photography solutions to boost your brand's visibility.
            </div>
          </div>
          <div className="imagem-complementos">
            <div className="container-imagem">
              <img src={img1complementos} className="imagens-complementos" />
              <div className="complementos-container">
                <div className="complementos-titulo-img">
                  Marketing <br /> and Strategy
                </div>
                <div className="complementos-subtitulo-img">
                  We offer a personalised marketing and strategy service to
                  boost the success of your business. From creating digital
                  strategies to implementing innovative tactics, we are
                  committed to optimising your online and offline presence,
                  generating measurable results. Count on us to guide your
                  company towards new achievements in the market.
                </div>
              </div>
            </div>
            <div className="container-imagem">
              <img src={img2complementos} className="imagens-complementos" />
              <div className="complementos-container">
                <div className="complementos-titulo-img">
                  Photography <br /> and Video
                </div>
                <div className="complementos-subtitulo-img">
                  We offer professional photography and video services to
                  capture special moments and highlight the uniqueness of your
                  event or business. With a creative approach and
                  state-of-the-art equipment, we guarantee impressive visual
                  results that meet your needs.
                </div>
              </div>
            </div>
            <div className="container-imagem">
              <img src={img2complementos} className="imagens-complementos" />
              <div className="complementos-container">
                <div className="complementos-titulo-img">Consultancy</div>
                <div className="complementos-subtitulo-img">
                  We offer a personalised marketing and strategy consultancy
                  service designed to boost the success of your business. From
                  drawing up digital strategies to implementing innovative
                  tactics, we are dedicated to optimising your presence both
                  online and offline, guaranteeing measurable results. Rely on
                  our expertise to guide your company towards new market
                  achievements.
                </div>
              </div>
            </div>
            <div className="container-imagem">
              <img src={img1complementos} className="imagens-complementos" />
              <div className="complementos-container">
                <div className="complementos-titulo-img">
                  Investment <br />
                  projects
                </div>
                <div className="complementos-subtitulo-img">
                  Specialised in investment projects, designed to maximise the
                  success of your portfolio. From identifying opportunities to
                  implementing effective strategies, we are dedicated to
                  optimising your investments, guaranteeing measurable results.
                  Rely on our expertise to guide your investments towards new
                  financial achievements.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
