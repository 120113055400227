import Navbar_PT from "../../components/Navbar/Navbar_PT";
import "./Complementos.css";
import img1complementos from "../../assets/images/imagem1complementos.png";
import img2complementos from "../../assets/images/imagem2complementos.png";
import img3complementos from "../../assets/images/imagem3complementos.png";
import img4complementos from "../../assets/images/imagem4complementos.png";
import { useEffect, useState } from "react";

export default function Complementos_PT() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1200);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1200);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      <Navbar_PT menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <div className={`home-container ${menuOpen ? "open" : ""}`}>
        <div className="complementos-content">
          <div className="complementos-text-container">
            <div class="complementos-titulo-page">
              <span class="servicos-gradient-text">Serviços</span>{" "}
              <span class="servicos-subtitulo">complementares</span>
            </div>
            <div className="text-introducao">
              Enriqueça a experiência com os nossos serviços adicionais,
              complementando o seu produto principal. Dispomos de soluções
              abrangentes em marketing e fotografia para potenciar a{" "}
              {isSmallScreen && <br />} visibilidade da sua marca.{" "}
            </div>
          </div>
          <div className="imagem-complementos">
            <div className="container-imagem">
              <img src={img1complementos} className="imagens-complementos" />
              <div className="complementos-container">
                <div className="complementos-titulo-img">
                  Marketing <br /> e Estratégia
                </div>
                <div className="complementos-subtitulo-img">
                  Oferecemos um serviço personalizado de marketing e estratégia
                  para impulsionar o sucesso do seu negócio. Da criação de
                  estratégias digitais à implementação de táticas inovadoras,
                  estamos comprometidos em otimizar a sua presença online e
                  offline, gerando resultados mensuráveis. Conte connosco para
                  guiar a sua empresa rumo a novas conquistas no mercado.
                </div>
              </div>
            </div>
            <div className="container-imagem">
              <img src={img2complementos} className="imagens-complementos" />
              <div className="complementos-container">
                <div className="complementos-titulo-img">
                  Fotografia <br /> e Vídeo
                </div>
                <div className="complementos-subtitulo-img">
                  Oferecemos serviços profissionais de fotografia e vídeo para
                  capturar momentos especiais e destacar a singularidade do seu
                  evento ou negócio. Com uma abordagem criativa e equipamento de
                  última geração, garantimos resultados visuais impressionantes
                  que atendem às suas necessidades.
                </div>
              </div>
            </div>
            <div className="container-imagem">
              <img src={img3complementos} className="imagens-complementos" />
              <div className="complementos-container">
                <div className="complementos-titulo-img">Consultoria</div>
                <div className="complementos-subtitulo-img">
                  Apresentamos um serviço de consultoria personalizado em
                  marketing e estratégia, concebido para potenciar o sucesso do
                  seu negócio. Desde a elaboração de estratégias digitais até à
                  implementação de táticas inovadoras, estamos dedicados a
                  otimizar a sua presença tanto online como offline, garantindo
                  resultados mensuráveis. Confie na nossa expertise para guiar a
                  sua empresa rumo a novas conquistas no mercado.
                </div>
              </div>
            </div>
            <div className="container-imagem">
              <img src={img4complementos} className="imagens-complementos" />
              <div className="complementos-container">
                <div className="complementos-titulo-img">
                  Projetos de
                  <br /> Investimentos
                </div>
                <div className="complementos-subtitulo-img">
                  Especializado em projetos de investimentos, concebido para
                  maximizar o sucesso do seu portfólio. Desde a identificação de
                  oportunidades até à implementação de estratégias eficazes,
                  estamos dedicados a otimizar os seus investimentos, garantindo
                  resultados mensuráveis. Confie na nossa expertise para guiar
                  os seus investimentos rumo a novas conquistas financeiras.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
